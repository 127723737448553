<template>
  <AppDialog ref="dialog">
    <v-card>
      <v-tabs>
        <v-tab>
          <v-icon left> mdi-inbox </v-icon>
          {{ $t('operations.pending') }} ({{
            items.filter(function (i) {
              return i.is_trained !== true && i.is_valid === true
            }).length
          }})
        </v-tab>
        <v-tab>
          <v-icon left> mdi-check </v-icon>
          {{ $t('operations.trained') }} ({{
            items.filter(function (i) {
              return i.is_trained === true && i.is_valid === true
            }).length
          }})
        </v-tab>
        <v-tab>
          <v-icon left> mdi-close </v-icon>
          {{ $t('operations.invalid') }} ({{
            items.filter(function (i) {
              return i.is_valid === false
            }).length
          }})
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  selected.filter(function (i) {
                    return i.is_trained !== true && i.is_valid === true
                  }).length > 0
                "
                color="blue-grey lighten-2 dark"
                class="mx-1"
                dark
                @click="setItemTrained"
                >{{ $t('operations.set_trained') }} ({{
                  selected.filter(function (i) {
                    return i.is_trained !== true && i.is_valid === true
                  }).length
                }})
              </v-btn>
              <v-btn
                color="blue-grey lighten-2 dark"
                class="mx-1"
                dark
                @click="exportItems"
                >{{ $t('operations.export') }}
              </v-btn>
              <v-btn
                color="blue-grey lighten-2 dark"
                class="mx-1"
                dark
                :loading="isSelecting"
                @click="importItems"
                >{{ $t('operations.import') }}
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".xlsx"
                  @change="onFileChanged"
                />
              </v-btn>
            </v-toolbar>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :loading="loading"
              :items="
                items.filter(function (i) {
                  return i.is_trained !== true && i.is_valid === true
                })
              "
              :single-select="false"
              :single-expand="true"
              item-key="identification_number"
              sort-by="identification_number"
              show-select
              show-expand
            >
              <template v-slot:[`item.is_trained`]="{ item }">
                {{ $t(`boolean.${item.is_trained}`) }}
              </template>
              <template v-slot:[`item.is_valid`]="{ item }">
                {{ $t(`boolean.${item.is_valid}`) }}
              </template>
              <template v-slot:[`item.requirements`]="{ item }">
                <v-card-text class="ma-0 pa-0">
                  <v-chip
                    :key="index"
                    v-for="(file, index) in item.requirements"
                    class="mr-2"
                    link
                    small
                    :href="file.url_download"
                    target="_blank"
                  >
                    <v-icon left small> mdi-paperclip </v-icon>
                    {{ file.name }}
                  </v-chip>
                </v-card-text>
              </template>
              <template v-slot:[`item.options`]="{ item }">
                <div>
                  <v-layout justify-end>
                    <v-btn
                      color="blue-grey lighten-2 dark"
                      class="mx-1"
                      dark
                      small
                      @click="trainedItem(item, true)"
                      >{{ $t('operations.trained') }}
                    </v-btn>
                    <v-btn
                      color="blue-grey lighten-2 dark"
                      class="mx-1"
                      dark
                      small
                      v-if="!item.is_trained"
                      @click="deleteItem(item)"
                      >{{ $t('operations.delete') }}
                    </v-btn>
                  </v-layout>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0 ma-0">
                  <v-card class="ma-0 pa-0" flat tile>
                    <v-card-title class="h6 white--text grey darken-1 pt-1">
                      {{ item.first_name }} {{ item.last_name }}
                      <v-spacer></v-spacer>
                      {{ item.identification_type_code }}:
                      {{ item.identification_number }}
                    </v-card-title>
                    <v-card-text class="grey lighten-3">
                      <v-row class="pt-3">
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.birthday') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.birthday }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.years_old') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.years_old }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.sex') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.sex_name }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.address') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.address }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.neighborhood') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.neighborhood_name }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.sector') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.sector_name }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.email') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.email }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.phone') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.phone }}
                            </p>
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="grey darken-1 ma-0 pa-0 pt-3">
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-toolbar flat> </v-toolbar>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :loading="loading"
              :items="
                items.filter(function (i) {
                  return i.is_trained === true && i.is_valid === true
                })
              "
              :single-expand="true"
              item-key="identification_number"
              sort-by="identification_number"
              show-expand
            >
              <template v-slot:[`item.is_trained`]="{ item }">
                {{ $t(`boolean.${item.is_trained}`) }}
              </template>
              <template v-slot:[`item.is_valid`]="{ item }">
                {{ $t(`boolean.${item.is_valid}`) }}
              </template>
              <template v-slot:[`item.requirements`]="{ item }">
                <v-card-text class="ma-0 pa-0">
                  <v-chip
                    :key="index"
                    v-for="(file, index) in item.requirements"
                    class="mr-2"
                    link
                    small
                    :href="file.url_download"
                    target="_blank"
                  >
                    <v-icon left small> mdi-paperclip </v-icon>
                    {{ file.name }}
                  </v-chip>
                </v-card-text>
              </template>
              <template v-slot:[`item.options`]="{ item }">
                <div>
                  <v-layout justify-end>
                    <v-btn
                      color="blue-grey lighten-2 dark"
                      class="mx-1"
                      dark
                      small
                      @click="trainedItem(item, false)"
                      >{{ $t('operations.pending') }}
                    </v-btn>
                  </v-layout>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0 ma-0">
                  <v-card class="ma-0 pa-0" flat tile>
                    <v-card-title class="h6 white--text grey darken-1 pt-1">
                      {{ item.first_name }} {{ item.last_name }}
                      <v-spacer></v-spacer>
                      {{ item.identification_type_code }}:
                      {{ item.identification_number }}
                    </v-card-title>
                    <v-card-text class="grey lighten-3">
                      <v-row class="pt-3">
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.birthday') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.birthday }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.years_old') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.years_old }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.sex') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.sex_name }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.address') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.address }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.neighborhood') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.neighborhood_name }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.sector') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.sector_name }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.email') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.email }}
                            </p>
                          </v-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <p
                            class="
                              text--primary
                              subtitle-1
                              font-weight-medium
                              ma-0
                            "
                          >
                            {{ $t('fields.phone') }}
                          </p>
                          <v-input class="input" disabled>
                            <p
                              class="text-body-1 grey--text text--darken-3 ma-0"
                            >
                              {{ item.phone }}
                            </p>
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="grey darken-1 ma-0 pa-0 pt-3">
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-toolbar flat> </v-toolbar>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :loading="loading"
            :items="
              items.filter(function (i) {
                return i.is_valid === false
              })
            "
            :single-expand="true"
            item-key="identification_number"
            sort-by="identification_number"
            show-expand
          >
            <template v-slot:[`item.is_trained`]="{ item }">
              {{ $t(`boolean.${item.is_trained}`) }}
            </template>
            <template v-slot:[`item.is_valid`]="{ item }">
              {{ $t(`boolean.${item.is_valid}`) }}
            </template>
            <template v-slot:[`item.requirements`]="{ item }">
              <v-card-text class="ma-0 pa-0">
                <v-chip
                  :key="index"
                  v-for="(file, index) in item.requirements"
                  class="mr-2"
                  link
                  small
                  :href="file.url_download"
                  target="_blank"
                >
                  <v-icon left small> mdi-paperclip </v-icon>
                  {{ file.name }}
                </v-chip>
              </v-card-text>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-0 ma-0">
                <v-card class="ma-0 pa-0" flat tile>
                  <v-card-title class="h6 white--text grey darken-1 pt-1">
                    {{ item.first_name }} {{ item.last_name }}
                    <v-spacer></v-spacer>
                    {{ item.identification_type_code }}:
                    {{ item.identification_number }}
                  </v-card-title>
                  <v-card-text class="grey lighten-3">
                    <v-row class="pt-3">
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.birthday') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.birthday }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.years_old') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.years_old }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.sex') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.sex_name }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.address') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.address }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.neighborhood') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.neighborhood_name }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.sector') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.sector_name }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.email') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.email }}
                          </p>
                        </v-input>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <p
                          class="
                            text--primary
                            subtitle-1
                            font-weight-medium
                            ma-0
                          "
                        >
                          {{ $t('fields.phone') }}
                        </p>
                        <v-input class="input" disabled>
                          <p class="text-body-1 grey--text text--darken-3 ma-0">
                            {{ item.phone }}
                          </p>
                        </v-input>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="grey darken-1 ma-0 pa-0 pt-3">
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <AppDialogConfirm ref="confirm" />
  </AppDialog>
</template>

<script>
import AppDialog from '@/components/dialogs/FullScreen.vue'
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
export default {
  name: 'TrainingCitizens',
  components: { AppDialog, AppDialogConfirm },
  data: () => ({
    url: null,
    id: null,
    selected: [],
    items: [],
    loading: null,
    isSelecting: false
  }),
  created() {
    this.name = this.$t('operations.citizens')
    this.url = 'citizens_training/citizens/'
    this.headers = [
      {
        text: this.$t('fields.type'),
        value: 'identification_type_code'
      },
      {
        text: this.$t('fields.identification_number'),
        value: 'identification_number',
        sortable: false
      },
      { text: this.$t('fields.first_name'), value: 'first_name' },
      { text: this.$t('fields.last_name'), value: 'last_name' },
      { text: this.$t('fields.requirements'), value: 'requirements' },
      { text: this.$t('fields.options'), value: 'options', align: 'right' },
      { text: '', value: 'data-table-expand' }
    ]
  },
  methods: {
    open(id) {
      let options = { color: 'teal' }
      let title = `${this.$t('operations.citizens').toUpperCase()} [${id}]`
      this.id = id
      this.getItems()
      return this.$refs.dialog.open(title, options)
    },

    getItems() {
      this.items = []
      this.selected = []
      this.loading = true
      this.$http.get(`${this.url}?training_id=${this.id}`).then((response) => {
        this.items = response.data
        this.loading = false
      })
    },

    importItems() {
      this.isSelecting = true
      window.addEventListener('focus', () => (this.isSelecting = false), {
        once: true
      })
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      const data = new FormData()
      data.append('file', e.target.files[0])
      this.$http
        .post(`${this.url}file/?training_id=${this.id}`, data)
        .then(() => {
          this.getItems()
          this.$refs.uploader.value = null
        })
        .catch(() => (this.$refs.uploader.value = null))
    },

    exportItems() {
      this.$http
        .get(`${this.url}file/?training_id=${this.id}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${this.name}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },

    deleteItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, message, options).then((confirm) => {
        if (confirm)
          this.$http
            .delete(`${this.url}?id=${item.id}`)
            .then(() => this.getItems())
      })
    },

    trainedItem(item, is_trained) {
      let options = { color: 'teal' }
      let title = this.$t('operations.set_trained').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, message, options).then((confirm) => {
        if (confirm)
          this.$http
            .put(
              `${this.url}?id=${item.id}`,
              JSON.stringify({ is_trained: is_trained })
            )
            .then(() => this.getItems())
      })
    },

    setItemTrained() {
      let options = { color: 'teal' }
      let title = this.$t('operations.set_trained').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      let data = this.selected
        .map(function (i) {
          return i.id
        })
        .join(',')
      this.$refs.confirm.open(title, messaje, options).then((confirm) => {
        if (confirm) {
          this.$http
            .put(this.url, JSON.stringify({ id__in: data, is_trained: true }))
            .then(() => {
              this.getItems()
            })
        }
      })
    }
  }
}
</script>

<style>
</style>