<template>
  <AppDialogItem ref="dialogItem" :isValid="isValid">
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
    <v-alert text color="teal" class="ma-0 px-10 py-3" dense v-if="url_form">
      <v-toolbar dense flat color="transparent" class="float-right ma-0 pa-0">
        <v-btn icon small color="teal" @click="copyText(url_form)">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn icon small color="teal" @click="openForm">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-toolbar>
      <p class="subtitle-2 pa-0 ma-0 py-1">{{ $t('fields.public_link') }}</p>
      <p class="caption pa-0 ma-0" id="url_form">{{ url_form }}</p>
    </v-alert>
    <v-card-text class="ma-0 pt-5">
      <v-form ref="form" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12" sm="12" md="8">
            <v-row>
              <v-col cols="12" sm="12" md="5">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.start_date"
                      v-on:input="$emit('v-text-field', $event)"
                      append-icon="mdi-calendar"
                      readonly
                      required
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        {{ $t('fields.start_date') }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.start_date"
                    @input="menu_1 = false"
                    locale="es-co"
                    color="teal"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.final_date"
                      v-on:input="$emit('v-text-field', $event)"
                      append-icon="mdi-calendar"
                      readonly
                      required
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        {{ $t('fields.final_date') }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.final_date"
                    @input="menu_2 = false"
                    locale="es-co"
                    color="teal"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="item.citizens_limit"
                  type="number"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.citizens_limit') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.name"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea counter rows="2" v-model="item.description">
                  <template v-slot:label>
                    {{ $t('fields.description') }}
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card-text class="pt-0 px-5">
              <v-toolbar flat color="transparent">
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey lighten-2 dark"
                  class="ma-0"
                  dark
                  small
                  @click="uploadFile()"
                  >{{ $t('operations.select_image') }}
                </v-btn>
              </v-toolbar>
            </v-card-text>
            <v-card-text class="ma-0 pa-0 px-15">
              <v-img v-if="image" :src="image" contain></v-img>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="item.training_type_id"
              :items="types"
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.type') }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              v-model="item.training_category_id"
              :items="categories"
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
              clearable
            >
              <template v-slot:label>
                {{ $t('fields.category') }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="item.training_level_id"
              :items="levels"
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.level') }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="item.training_status_id"
              :items="
                status.filter(function (i) {
                  return i.is_finished == false
                })
              "
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.status') }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="item.training_mode_id"
              :items="modes"
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.mode') }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
              v-model="item.training_instructor_id"
              :items="instructors"
              item-text="name_extended"
              item-value="id"
              :rules="formRules"
              required
              clearable
            >
              <template v-slot:label>
                {{ $t('fields.instructor') }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field v-model="item.evidence" autocomplete="off">
              <template v-slot:label>
                {{ $t('fields.evidence') }}
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea counter rows="2" v-model="item.observations">
              <template v-slot:label>
                {{ $t('fields.observations') }}
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </AppDialogItem>
</template>

<script>
import AppDialogItem from '@/components/dialogs/FullScreen.vue'
export default {
  name: 'Training',
  components: {
    AppDialogItem
  },
  props: ['url', 'status', 'types'],
  data: () => ({
    url_form: null,
    formRules: [(v) => !!v || 'Required'],
    levels: [],
    categories: [],
    modes: [],
    instructors: [],
    item: {},
    image: null,
    image_file: null,
    menu_1: false,
    menu_2: false,
    isValid: false
  }),

  created() {
    this.url_instructors = 'citizens_training/instructors/'
    this.url_categories = 'citizens_training/categories/'
    this.url_levels = 'citizens_training/levels/'
    this.url_modes = 'citizens_training/modes/'

    this.getInstructors()
    this.getLevels()
    this.getModes()
  },

  methods: {
    newItem(item) {
      this.getCategories(item.dependency_id)
      let options = { color: 'teal', save: this.$t('operations.save') }
      let title = this.$t('operations.new').toUpperCase()
      this.url_form = null
      this.image = null
      this.image_file = null
      this.isNewItem = true
      this.item = Object.assign({}, item)
      return this.$refs.dialogItem.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate()) {
          this.$http.post(this.url, JSON.stringify(this.item)).then(() => {
            if (this.image_file) {
              fetch(item.url_upload, { method: 'PUT', body: this.image_file })
            }
          })
        }
      })
    },

    editItem(item) {
      this.getCategories(item.dependency_id)
      let options = { color: 'teal', save: this.$t('operations.save') }
      let title = `${this.$t('operations.editing').toUpperCase()} [${item.id}]`
      this.url_form = `${window.location.origin}/#/citizens_training/training/${item.id}`
      this.image = item.url_download
      this.image_file = null
      this.isNewItem = false
      this.item = Object.assign(
        {},
        {
          id: item.id,
          start_date: item.start_date,
          final_date: item.final_date,
          citizens_limit: item.citizens_limit,
          name: item.name,
          description: item.description,
          evidence: item.evidence,
          observations: item.observations,
          training_type_id: item.training_type_id,
          training_mode_id: item.training_mode_id,
          training_category_id: item.training_category_id,
          training_level_id: item.training_level_id,
          training_status_id: item.training_status_id,
          training_instructor_id: item.training_instructor_id
        }
      )
      return this.$refs.dialogItem.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http
            .put(`${this.url}?id=${this.item.id}`, JSON.stringify(this.item))
            .then(() => {
              if (this.image_file) {
                fetch(item.url_upload, { method: 'PUT', body: this.image_file })
              }
            })
      })
    },

    uploadFile() {
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      this.showImage = false
      this.image_file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        this.image = reader.result
        this.showImage = true
      }
      this.$refs.uploader.value = null
    },

    copyText() {
      var node = document.getElementById('url_form')
      if (document.body.createTextRange) {
        const range = document.body.createTextRange()
        range.moveToElementText(node)
        range.select()
      } else if (window.getSelection) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(node)
        selection.removeAllRanges()
        selection.addRange(range)
      }

      document.execCommand('copy')

      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
    },

    openForm() {
      window.open(this.url_form)
    },

    getLevels() {
      this.$http.get(this.url_levels).then((request) => {
        this.levels = request.data
      })
    },

    getCategories(dependency_id) {
      this.$http
        .get(`${this.url_categories}?dependency_id=${dependency_id}`)
        .then((request) => {
          this.categories = request.data
        })
    },

    getModes() {
      this.$http.get(this.url_modes).then((request) => {
        this.modes = request.data
      })
    },

    getInstructors() {
      this.$http.get(this.url_instructors).then((request) => {
        request.data.forEach(function (i) {
          i.name_extended = `${i.institution_code} - ${i.name}`
        })
        this.instructors = request.data
      })
    }
  }
}
</script>

<style>
</style>