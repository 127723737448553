<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selected.length > 0"
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="deleteMultipleItems"
        >{{ $t('operations.delete') }} ({{ selected.length }})
      </v-btn>
      <v-btn
        v-if="dependency_id && training_location_id"
        color="teal dark"
        dark
        class="mx-1"
        @click="newItem"
      >
        {{ $t('operations.new') }}
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="4" md="3">
          <v-select
            @change="getItems()"
            v-model="dependency_id"
            :items="dependencies"
            item-text="name"
            item-value="id"
          >
            <template v-slot:label>
              {{ $t('fields.dependency') }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-select
            @change="getItems()"
            v-model="training_location_id"
            :items="locations"
            item-text="name"
            item-value="id"
          >
            <template v-slot:label>
              {{ $t('fields.location') }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            autocomplete="off"
          >
            <template v-slot:label>{{ $t('operations.search') }} </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-alert
      type="info"
      border="left"
      v-if="!dependency_id || !training_location_id"
    >
      {{ $t('message.select_filter_to_continue') }}
    </v-alert>
    <!-- table -->
    <v-data-table
      v-if="dependency_id && training_location_id"
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="false"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: $t('operations.items_per_page')
      }"
      sort-by="id"
      show-select
    >
      <template v-slot:loading>{{ $t('operations.loading') }} </template>
      <template v-slot:[`item.options`]="{ item }">
        <v-layout justify-end>
          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="editItem(item)"
            >{{ $t('operations.edit') }}
          </v-btn>
          <v-btn
            color="blue-grey datken-2 dark"
            class="ml-1"
            dark
            small
            @click="itemCitizens(item)"
            >{{ $t('operations.citizens') }}
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue-grey darken-2"
                class="ml-1"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="itemRequirements(item)">
                  {{ $t('operations.requirements') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="itemConditions(item)">
                  {{ $t('operations.conditions') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link v-if="item.enrolled === 0">
                <v-list-item-title @click="deleteItem(item)">
                  {{ $t('operations.delete') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link v-if="item.evidence" :disabled="canFinalize">
                <v-list-item-title @click="finalizeItem(item)">
                  {{ $t('operations.finalize') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
      </template>
    </v-data-table>

    <!-- finalize form -->
    <AppDialogForm ref="dialogFinalizeItem" :isValid="finalizeIsValid">
      <v-form ref="finalizeForm" lazy-validation v-model="finalizeIsValid">
        <v-row class="pt-5" v-if="is_training">
          <v-col cols="12">
            <v-alert type="error" border="left" v-if="trained <= 0">
              {{ $t('message.no_citizens_trained') }}
            </v-alert>
          </v-col>
          <v-col cols="6">
            {{ $t('cards.enrolled') }}
          </v-col>
          <v-col cols="6" align="right">
            {{ enrolled }}
          </v-col>
          <v-col cols="6">
            {{ $t('cards.trained') }}
          </v-col>
          <v-col cols="6" align="right">
            {{ trained }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-divider></v-divider>
            <v-select
              v-model="training_status_id"
              :items="
                status.filter(function (i) {
                  return i.is_finished == true
                })
              "
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.status') }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <AppDialogItem
      ref="dialogItem"
      :status="status"
      :types="types"
      :url="url"
    />
    <AppDialogCitizens ref="dialogCitizens" />
    <AppDialogRequirements ref="dialogRequirements" />
    <AppDialogConditions ref="dialogConditions" />
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
import AppDialogItem from './Training'
import AppDialogCitizens from './TrainingCitizens'
import AppDialogRequirements from './TrainingRequirements'
import AppDialogConditions from './TrainingConditions'

export default {
  name: 'Trainings',
  components: {
    AppDialogConfirm,
    AppDialogForm,
    AppDialogItem,
    AppDialogCitizens,
    AppDialogRequirements,
    AppDialogConditions
  },
  data: () => ({
    name: null,
    url: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    items: [],
    dependencies: [],
    locations: [],
    status: [],
    types: [],
    training_location_id: null,
    training_status_id: null,
    dependency_id: null,
    enrolled: null,
    trained: null,
    is_training: null,
    item: {},
    selected: [],
    loading: false,
    loading_text: null,
    search: null,
    isValid: false,
    finalizeIsValid: false,
    isNewItem: null,
    canFinalize: true
  }),

  created() {
    this.url = 'citizens_training/trainings/'
    this.url_dependencies = 'user/dependencies/'
    this.url_locations = 'user/training_locations/'
    this.url_status = 'citizens_training/status/'
    this.url_types = 'citizens_training/types/'

    this.name = this.$t('options.citizens_training.trainings')
    this.headers = [
      { text: 'Id', value: 'id' },
      {
        text: this.$t('fields.type'),
        value: 'training_type_name'
      },
      {
        text: this.$t('fields.mode'),
        value: 'training_mode_name'
      },
      {
        text: this.$t('fields.category'),
        value: 'training_category_name'
      },
      {
        text: this.$t('fields.level'),
        value: 'training_level_name'
      },
      { text: this.$t('fields.name'), value: 'name' },
      { text: this.$t('fields.start_date'), value: 'start_date' },
      { text: this.$t('fields.final_date'), value: 'final_date' },
      {
        text: this.$t('fields.status'),
        value: 'training_status_name'
      },
      { text: this.$t('fields.options'), value: 'options', align: 'right' }
    ]

    this.getDepencencies()
    this.getLocations()
    this.getStatus()
    this.getTypes()
  },

  methods: {
    getItems() {
      this.loading = true
      this.items = []
      if (this.dependency_id && this.training_location_id) {
        let url = `${this.url}?is_finished=0`
        url += `&dependency_id=${this.dependency_id}`
        url += `&training_location_id=${this.training_location_id}`
        this.$http.get(url).then((request) => {
          this.loading = false
          this.items = request.data
        })
        var dependency_id = this.dependency_id
        var dependency = this.dependencies.filter(function (item) {
          return item.id == dependency_id
        })

        if (dependency.length > 0) {
          var report_limit_day = dependency[0].report_limit_day
          if (new Date().getDate() < report_limit_day) {
            this.canFinalize = false
          }
        }
      }
    },

    newItem() {
      let item = {
        dependency_id: this.dependency_id,
        training_location_id: this.training_location_id
      }
      this.$refs.dialogItem.newItem(item).then(() => {
        this.getItems()
      })
    },

    editItem(item) {
      this.$refs.dialogItem.editItem(item).then(() => {
        this.getItems()
      })
    },

    deleteItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, message, options).then((confirm) => {
        if (confirm)
          this.$http
            .delete(`${this.url}?id=${item.id}`)
            .then(() => this.getItems())
      })
    },

    async deleteMultipleItems() {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      let confirm = await this.$refs.confirm.open(title, message, options)
      if (await confirm) {
        var processed = 0
        this.selected.forEach(async (item) => {
          await this.$http.delete(`${this.url}?id=${item.id}`).then(() => {
            processed++
            if (processed === this.selected.length) {
              this.getItems()
            }
          })
        })
      }
    },

    finalizeItem(item) {
      console.log(new Date().getDate())
      let options = { color: 'teal' }
      let title = `${this.$t('operations.finalize').toUpperCase()} [${item.id}]`
      this.training_status_id = null
      this.enrolled = item.enrolled
      this.trained = item.trained
      this.is_training = this.types.find(
        (x) => x.id == item.training_type_id
      ).is_training
      this.$refs.dialogFinalizeItem.open(title, options).then((confirm) => {
        if (
          confirm &&
          this.$refs.finalizeForm.validate() &&
          this.training_status_id
        ) {
          let data = {
            is_training: this.types.find((x) => x.id == item.training_type_id)
              .is_training,
            is_finished: this.status.find(
              (x) => x.id == this.training_status_id
            ).is_finished,
            show_in_reports: this.status.find(
              (x) => x.id == this.training_status_id
            ).show_in_reports,
            training_status_id: this.training_status_id
          }
          this.$http
            .put(`${this.url}?id=${item.id}`, JSON.stringify(data))
            .then(() => this.getItems())
        }
      })
    },

    getLocations() {
      this.$http.get(this.url_locations).then((request) => {
        this.locations = request.data
      })
    },

    getDepencencies() {
      this.$http.get(this.url_dependencies).then((request) => {
        this.dependencies = request.data
      })
    },

    getStatus() {
      this.$http.get(this.url_status).then((request) => {
        this.status = request.data
      })
    },

    getTypes() {
      this.$http.get(this.url_types).then((request) => {
        this.types = request.data
      })
    },

    itemCitizens(item) {
      this.$refs.dialogCitizens.open(item.id).then(() => this.getItems())
    },

    itemRequirements(item) {
      this.$refs.dialogRequirements.open(item.id).then(() => this.getItems())
    },

    itemConditions(item) {
      this.$refs.dialogConditions.open(item.id).then(() => this.getItems())
    },
    copyText() {
      var copyText = document.querySelector('#enroll_form_url')
      copyText.select()
      document.execCommand('copy')
    }
  }
}
</script>

<style></style>
