<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        autocomplete="off"
      >
        <template v-slot:label
          >{{ $t('message.write_items_to_search') }}
        </template>
      </v-text-field>
      <v-btn
        color="blue-grey lighten-2 dark"
        class="ml-4"
        dark
        @click="getItems()"
        >{{ $t('operations.search') }}
      </v-btn>
    </v-card-title>
    <v-divider class="pt-4 pb-4"></v-divider>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(item, index) in items"
        :key="index"
      >
        <v-card class="mx-auto">
          <v-card-text>
            <v-chip class="card_id">{{ item.id }}</v-chip>
            <p absolute></p>
            <div>
              {{ item.training_type_name }} /
              {{ item.training_category_name }} /
              {{ item.training_level_name }}
            </div>
            <p class="text-h6 text--primary pt-2">{{ item.name }}</p>
            <v-row>
              <v-col cols="6">
                <p class="text-subtitle-2 font-italic text--primary pr-5">
                  {{ item.training_location_name }}
                </p>
              </v-col>
              <v-col cols="6">
                <div>
                  {{ String(item.enrolled).padStart(2, 0) }}
                  {{ $t('cards.enrolled') }}
                </div>
                <div>
                  {{ String(item.trained).padStart(2, 0) }}
                  {{ $t('cards.trained') }}
                </div>
              </v-col>
            </v-row>

            <v-row class="pt-0">
              <v-col cols="6">
                <v-text-field v-model="item.start_date" readonly disabled
                  ><template v-slot:label>
                    {{ $t('fields.start_date') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="item.final_date" readonly disabled
                  ><template v-slot:label>
                    {{ $t('fields.final_date') }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <p class="pl-3">{{ item.training_status_name }}</p>

            <v-spacer></v-spacer>
            <v-btn
              dark
              color="blue-grey lighten-2 dark"
              @click="activateItem(item)"
              v-if="item.is_finished"
            >
              {{ $t('operations.enable') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- form -->
    <AppDialogForm ref="dialogActivateItem" :isValid="isValid">
      <v-form ref="activateForm" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-divider></v-divider>
            <v-select
              v-model="training_status_id"
              :items="
                status.filter(function (i) {
                  return i.is_finished == false
                })
              "
              item-text="name"
              item-value="id"
              required
            >
              <template v-slot:label>
                {{ $t('fields.status') }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <!-- confirm -->
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
export default {
  name: 'Causes',
  components: { AppDialogConfirm, AppDialogForm },
  data: () => ({
    name: null,
    url: null,
    url_file: null,
    url_status: null,
    training_status_id: null,
    items: [],
    status: [],
    item: {},
    search: null,
    isValid: false
  }),

  created() {
    this.url = 'citizens_training/trainings/'
    this.url_file = 'citizens_training/trainings/file/'
    this.url_status = 'citizens_training/status/'
    this.name = this.$t('options.citizens_training.view_trainings')
    this.getStatus()
  },

  methods: {
    getItems() {
      this.items = []
      let url = `${this.url}?id__in=${this.search}`
      if (this.search) {
        this.$http.get(url).then((request) => {
          this.loading = false
          this.items = request.data
        })
      }
    },

    activateItem(item) {
      let options = { color: 'teal' }
      let title = `${this.$t('operations.enable').toUpperCase()} [${item.id}]`
      this.training_status_id = null
      this.$refs.dialogActivateItem.open(title, options).then((confirm) => {
        if (
          confirm &&
          this.$refs.activateForm.validate() &&
          this.training_status_id
        ) {
          let data = {
            is_finished: false,
            training_status_id: this.training_status_id
          }
          this.$http
            .put(`${this.url}?id=${item.id}`, JSON.stringify(data))
            .then(() => this.getItems())
        }
      })
    },

    getStatus() {
      this.$http.get(this.url_status).then((request) => {
        this.status = request.data
      })
    }
  }
}
</script>

<style scoped>
.card_id {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
