<template>
  <v-card flat>
    <v-alert type="error" border="left" v-if="!item.id || item.is_finished">
      {{ $t('message.resource_not_available') }}
    </v-alert>
    <v-row v-if="item.id && !item.is_finished">
      <v-col cols="12" sm="12" md="12">
        <v-alert tile type="info" border="left">
          <strong>{{ item.dependency_name }}</strong>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-img :src="item.url_download" height="300" contain> </v-img>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-container
          class="display-2 text--primary"
          style="white-space: pre-wrap"
          >{{ item.name }}
        </v-container>
        <v-card-subtitle class="mt-1 mb-1">
          <pre style="white-space: pre-wrap">{{ item.description }}</pre>
        </v-card-subtitle>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-alert
      type="info"
      border="left"
      v-if="
        item.id && !item.is_finished && item.enrolled >= item.citizens_limit
      "
    >
      {{ $t('message.enrollment_limit_reached') }}
    </v-alert>
    <v-card-text
      class="pa-0 ma-0"
      v-if="item.id && !item.is_finished && item.enrolled < item.citizens_limit"
    >
      <CitizenRegistry
        ref="enrollForm"
        v-on:registry-completed="citizenEnroll"
        :requirements="item.requirements"
        :conditions="item.conditions"
        :bucket-name="item.bucket_name"
        :folder-name="item.folder_name"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CitizenRegistry from '@/components/public_forms/CitizenRegistry.vue'
export default {
  name: 'EnrollForm',
  components: { CitizenRegistry },
  data: () => ({
    url: null,
    training_id: null,
    avilable: 0,
    item: {}
  }),

  created() {
    try {
      this.url = 'citizens_training/enroll/'
      this.training_id = this.$route.params.id
      this.getItem()
    } catch {
      this.training_id = null
    }
  },

  methods: {
    getItem() {
      let url = `${this.url}?id=${this.training_id}`
      this.$http.get(url).then((request) => {
        this.item = request.data
      })
    },

    citizenEnroll(citizen_id) {
      let data = { citizen_id: citizen_id, training_id: this.training_id }
      this.$http.post(this.url, data)
    }
  }
}
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>