<template>
  <AppDialog ref="dialog">
    <v-card>
      <v-list>
        <v-list-item-group multiple>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @change="change(item)"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ item.name }}:</strong> {{ item.description }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="item.is_active"></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </AppDialog>
</template>

<script>
import AppDialog from '@/components/dialogs/FullScreen.vue'
export default {
  name: 'CitizenTrainingConditions',
  components: { AppDialog },
  data: () => ({ url: null, items: [], training_id: null }),

  created() {
    this.url = 'citizens_training/conditions/'
  },

  methods: {
    open(id) {
      this.training_id = id
      this.getItems()
      let options = { color: 'teal' }
      let title = `${this.$t('operations.conditions').toUpperCase()} [${id}]`
      return this.$refs.dialog.open(title, options).then(() => {})
    },

    getItems() {
      this.$http
        .get(`${this.url}?training_id=${this.training_id}`)
        .then((response) => (this.items = response.data))
    },

    change(item) {
      item.is_active = !item.is_active
      item.condition_id = item.id
      item.training_id = this.training_id
      this.$http.post(this.url, JSON.stringify(item))
    }
  }
}
</script>

<style>
</style>