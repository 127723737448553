<template>
  <v-card flat>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selected_dependencies.length > 0 && selected_fields.length > 0"
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="exportItems"
        >{{ $t('operations.export') }}
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="4" md="2">
          <v-select
            @change="getData()"
            v-model="period"
            :items="periods"
            item-text="text"
            item-value="value"
          >
            <template v-slot:label>
              {{ $t('fields.period') }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="4" md="2">
          <v-text-field @input="getData()" v-model="year_from" type="number">
            <template v-slot:label>
              {{ $t('fields.from') }}
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="4" md="2">
          <v-text-field @input="getData()" v-model="year_to" type="number">
            <template v-slot:label>
              {{ $t('fields.to') }}
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="6">
          <v-select
            @change="getData()"
            v-model="selected_fields"
            :items="fields"
            item-text="text"
            item-value="value"
            multiple
            chips
            dense
          >
            <template v-slot:label>
              {{ $t('fields.fields') }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-select
            @change="getData()"
            v-model="selected_dependencies"
            :items="dependencies"
            item-text="name"
            item-value="id"
            multiple
            chips
            dense
          >
            <template v-slot:label>
              {{ $t('fields.dependency') }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-select
            @change="getData()"
            v-model="selected_locations"
            :items="locations"
            item-text="name"
            item-value="id"
            multiple
            chips
            dense
          >
            <template v-slot:label>
              {{ $t('fields.location') }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-alert
      type="info"
      border="left"
      v-if="
        !year_from ||
        !year_to ||
        !selected_dependencies.length > 0 ||
        !selected_fields.length > 0
      "
    >
      {{ $t('message.select_filter_to_continue') }}
    </v-alert>
    <v-alert
      type="info"
      color="grey"
      border="left"
      v-if="
        year_from &&
        year_to &&
        selected_dependencies.length > 0 &&
        selected_fields.length > 0 &&
        !items.data
      "
    >
      {{ $t('message.no_results') }}
    </v-alert>
    <v-row
      class="pt-7"
      v-if="
        year_from &&
        year_to &&
        selected_dependencies.length > 0 &&
        selected_fields.length > 0 &&
        items.data
      "
    >
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-card class="mt-4" elevation="10">
          <v-sheet
            class="v-sheet--offset text-center"
            dark
            color="teal"
            elevation="6"
          >
            <div
              class="text-h3 font-weight-bold pa-2 pr-5 pl-5"
              v-text="items.enrolled"
              h1
            ></div>
          </v-sheet>
          <v-card-text class="pt-6">
            <div class="title font-weight-bold text-h5 mb-2">
              {{ $t('cards.enrolled') }}
            </div>
            <v-divider></v-divider>
            <span class="caption font-weight-light">
              {{ $t('cards.enrolled_text') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-card class="mt-4" elevation="10">
          <v-sheet
            class="v-sheet--offset text-center"
            dark
            color="teal"
            elevation="6"
          >
            <div
              class="text-h3 font-weight-bold pa-2 pr-5 pl-5"
              v-text="items.trained"
              h1
            ></div>
          </v-sheet>
          <v-card-text class="pt-6">
            <div class="title font-weight-bold text-h5 mb-2">
              {{ $t('cards.trained') }}
            </div>
            <v-divider></v-divider>
            <span class="caption font-weight-light">
              {{ $t('cards.trained_text') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-card class="mt-4" elevation="10">
          <v-sheet
            class="v-sheet--offset text-center"
            dark
            color="teal"
            elevation="6"
          >
            <div
              class="text-h3 font-weight-bold pa-2 pr-5 pl-5"
              v-text="`${items.desertion}%`"
              h1
            ></div>
          </v-sheet>
          <v-card-text class="pt-6">
            <div class="title font-weight-bold text-h5 mb-2">
              {{ $t('cards.desertion') }}
            </div>
            <v-divider></v-divider>
            <span class="caption font-weight-light">
              {{ $t('cards.desertion_percent') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text dark class="pa-0 mt-10" v-if="items.summary">
      <v-chip dark label>
        <v-icon left> mdi-label </v-icon>
        {{ $t('fields.summary').toUpperCase() }}
      </v-chip>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers_summary"
        :items="items.summary"
        :disable-pagination="true"
        :hide-default-footer="true"
        :single-select="false"
        class="report_table"
      >
        <template v-slot:[`item.label`]="{ item }">
          {{ $t(`cards.${item.label}`) }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text dark class="pa-0 mt-10" v-if="items.data">
      <v-chip dark label>
        <v-icon left> mdi-label </v-icon>
        {{ $t('fields.details').toUpperCase() }}
      </v-chip>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="items.data"
        :disable-pagination="true"
        :hide-default-footer="true"
        :single-select="false"
        class="report_table"
      >
      </v-data-table>
    </v-card-text>
    <v-card-text dark class="pa-0 mt-10" v-if="items.general">
      <v-chip dark label>
        <v-icon left> mdi-label </v-icon>
        {{ $t('fields.management').toUpperCase() }}
      </v-chip>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers_general"
        :items="items.general"
        :disable-pagination="true"
        :hide-default-footer="true"
        :single-select="false"
        class="report_table"
      >
      </v-data-table>
    </v-card-text>
    <!-- export form -->
    <AppDialogForm ref="dialogExport" :isValid="exportIsValid">
      <v-form ref="exportForm" lazy-validation v-model="exportIsValid">
        <v-row class="pt-5">
          <v-col cols="12">
            {{ $t('message.select_range') }}
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="start_date" autocomplete="off" required>
              <template v-slot:label>
                {{ $t('fields.start_date') }} ({{ $t('fields.yyyy-mm-dd') }})
              </template>
              <template v-slot:append-outer>
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon> mdi-calendar </v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    @input="menu_1 = false"
                    locale="es-co"
                    color="teal"
                  >
                  </v-date-picker>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="final_date" autocomplete="off" required>
              <template v-slot:label>
                {{ $t('fields.start_date') }} ({{ $t('fields.yyyy-mm-dd') }})
              </template>
              <template v-slot:append-outer>
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon> mdi-calendar </v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="final_date"
                    @input="menu_2 = false"
                    locale="es-co"
                    color="teal"
                  >
                  </v-date-picker>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
  </v-card>
</template>

<script>
import AppDialogForm from '@/components/dialogs/Form.vue'
export default {
  name: 'ReportTraining',
  components: { AppDialogForm },

  data: () => ({
    name: null,
    url: null,
    year_from: new Date().getFullYear(),
    year_to: new Date().getFullYear(),
    dependencies: [],
    selected_dependencies: [],
    locations: [],
    selected_locations: [],
    fields: [],
    selected_fields: ['location_name'],
    export_fields: [],
    period: 'quarter',
    start_date: null,
    final_date: null,
    period_headers: [],
    items: {},
    periods: [],
    headers: [],
    headers_summary: [],
    headers_general: [],
    exportIsValid: false,
    menu_1: false,
    menu_2: false
  }),

  created() {
    this.url = 'citizens_training/report_trainings/'
    this.url_file = 'citizens_training/report_trainings/file/'
    this.url_dependencies = 'user/dependencies/'
    this.url_locations = 'user/training_locations/'

    this.name = this.$t('options.citizens_training.report_trainings')
    this.fields = [
      { text: this.$t('fields.type'), value: 'type_name', resume: true },
      {
        text: this.$t('fields.category'),
        value: 'category_name',
        resume: true
      },
      { text: this.$t('fields.level'), value: 'level_name', resume: true },
      { text: this.$t('fields.name'), value: 'name', resume: true },
      { text: this.$t('fields.mode'), value: 'mode_name' },
      {
        text: this.$t('fields.location'),
        value: 'location_name',
        resume: true
      }
    ]
    this.export_fields = [{ text: 'ID', value: 'id', resume: true }].concat(
      this.fields,
      [
        {
          text: this.$t('fields.start_date'),
          value: 'start_date',
          resume: true
        },
        {
          text: this.$t('fields.final_date'),
          value: 'final_date',
          resume: true
        },
        { text: this.$t('fields.year'), value: 'year', resume: true },
        { text: this.$t('fields.month'), value: 'month', resume: true },
        { text: this.$t('fields.quarter'), value: 'quarter', resume: true },
        { text: this.$t('fields.evidence'), value: 'evidence', resume: true },
        {
          text: this.$t('fields.identification_type'),
          value: 'identification_type_code'
        },
        {
          text: this.$t('fields.identification_number'),
          value: 'identification_number'
        },
        { text: this.$t('fields.first_name'), value: 'first_name' },
        { text: this.$t('fields.last_name'), value: 'last_name' },
        { text: this.$t('fields.email'), value: 'email' },
        { text: this.$t('fields.phone'), value: 'phone' }
      ]
    )

    this.periods = [
      { text: this.$t('fields.month'), value: 'month' },
      { text: this.$t('fields.quarter'), value: 'quarter' },
      { text: this.$t('fields.year'), value: 'year' }
    ]
    this.getDependencies()
    this.getLocations()
  },

  methods: {
    getData() {
      this.setHeaders()
      this.items = {}
      if (
        this.year_from &&
        this.year_to &&
        this.selected_dependencies.length > 0 &&
        this.selected_fields.length > 0
      ) {
        let data = {
          fields: this.selected_fields,
          period: this.period,
          period_headers: this.period_headers,
          dependency_id: this.selected_dependencies,
          location_id: this.selected_locations,
          start_date: `${this.year_from}-01-01`,
          final_date: `${this.year_to}-12-31`
        }
        this.$http.post(this.url, JSON.stringify(data)).then((request) => {
          this.loading = false
          this.items = request.data
        })
      }
    },

    setHeaders() {
      let selected_fields = this.selected_fields
      let headers = this.fields.filter(function (i) {
        return selected_fields.includes(i.value)
      })
      let period_headers = []
      let has_prefix = this.year_from === this.year_to ? false : true
      let steps = null
      steps = this.period === 'month' ? 12 : steps
      steps = this.period === 'quarter' ? 4 : steps
      steps = this.period === 'year' ? 1 : steps
      for (let year = this.year_from; year <= this.year_to; year++) {
        for (let i = 1; i <= steps; i++) {
          let prefix = has_prefix ? `${year} - ` : ''
          period_headers.push({
            text:
              this.period === 'year'
                ? year
                : prefix + this.$t(`${this.period}.${i}`),
            value: `t${year}${String(i).padStart(2, '0')}`,
            align: 'right'
          })
        }
      }

      this.period_headers = period_headers
      this.headers = headers.concat(period_headers, [
        { text: this.$t('fields.total'), value: 'trained', align: 'right' }
      ])
      this.headers_summary = [].concat(
        [{ text: null, value: 'label' }],
        period_headers
      )
      this.headers_general = [].concat(
        { text: this.$t('fields.type'), value: 'type_name' },
        period_headers,
        [{ text: this.$t('fields.total'), value: 'total', align: 'right' }]
      )
    },

    exportItems() {
      let options = { color: 'teal' }
      let title = this.$t('operations.export').toUpperCase()
      this.start_date = new Date().getFullYear()+'-01-01'
      this.final_date = new Date().getFullYear()+'-12-31'
      this.$refs.dialogExport.open(title, options).then((confirm) => {
        if (confirm) {
          let data = {
            fields: this.export_fields,
            dependency_id: this.selected_dependencies,
            location_id: this.selected_locations,
            period: this.period,
            start_date: this.start_date,
            final_date: this.final_date
          }
          this.$http
            .post(this.url_file, data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel'
              })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `${this.name}.xlsx`
              link.click()
              URL.revokeObjectURL(link.href)
            })
        }
      })
    },

    getDependencies() {
      this.$http.get(this.url_dependencies).then((request) => {
        this.dependencies = request.data
        this.selected_dependencies = this.dependencies.map(function (i) {
          return i.id
        })
        this.getData()
      })
    },

    getLocations() {
      this.$http.get(this.url_locations).then((request) => {
        this.locations = request.data
      })
    }
  }
}
</script>

<style>
.v-sheet--offset {
  top: -12px;
  right: 6px;
  position: absolute;
}
.report_table > .v-data-table__wrapper > table > tbody > tr > th,
.report_table > .v-data-table__wrapper > table > thead > tr > th,
.report_table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 20px !important;
}
.report_table > .v-data-table__wrapper > table > tbody > tr > td,
.report_table > .v-data-table__wrapper > table > thead > tr > td,
.report_table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 18px !important;
}
</style>